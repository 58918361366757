import { Tooltip } from "@mui/material";
import { MDBCol, MDBContainer, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Toggle from "react-toggle";
import { configuratorId } from "./data";

const Ash = ({ token }) => {
  const [dimensionEnabled, setDimensionEnabled] = useState(false);
  /**
   * Active Status for Swatches
   */
  const setActive = (event) => {
    // find the swatches collection you want to apply this function too
    var tempName = event.currentTarget.getAttribute("data-name"); // Use getAttribute to access data-* attribute
    var elements = document.querySelectorAll(`[data-name="${tempName}"]`);

    // remove all the active status
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.remove("active");
    }

    // add the status on the one that is clicked!!
    event.currentTarget.classList.add("active");
  };

  const handleDimensionChange = () => {
    setDimensionEnabled((prevState) => !prevState);
    if (window.configurator) {
      if (dimensionEnabled) {
        window.configurator.setConfiguration({ Dimensions: false });
        setDimensionEnabled(false);
      } else {
        window.configurator.setConfiguration({ Dimensions: true });
        setDimensionEnabled(true);
      }
    }
  };
  const openNav = () => {
    if (window.innerWidth > 600) {
      document.getElementById("mySidenav").style.width = "450px";
      document.getElementById("verticalBar").style.marginRight = "450px";
    } else {
      document.getElementById("mySidenav").style.width = "225px";
      document.getElementById("verticalBar").style.marginRight = "225px";
    }
    document.getElementById("verticalBar").style.backgroundColor = "#111111c6";
    document.getElementById("verticalBar").style.color = "white";
    document.getElementById("customize").classList.remove("cursor");

    document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
    var elements = document.querySelectorAll(`[data-name="image"]`);

    // remove all the active status
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.add("invert");
      elements[i].classList.remove("cursor");
    }
  };
  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("verticalBar").style.marginRight = "0";
    document.getElementById("verticalBar").style.backgroundColor = "#1111114e";
    document.getElementById("verticalBar").style.color = "black";
    document.body.style.backgroundColor = "white";
    document.getElementById("customize").classList.add("cursor");
    var elements = document.querySelectorAll(`[data-name="image"]`);

    // remove all the active status
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.remove("invert");
      elements[i].classList.add("cursor");
    }
  };

  const setColor = (event, colorId) => {
    if (window.configurator) {
      window.configurator.setConfiguration({
        Fabric: { assetId: colorId },
      });

      setActive(event);
    }
  };
  useEffect(() => {
    const playerElement = document.getElementById("player");
    // console.log(playerElement); // Check if this logs the element
    let dimensionChecked = false;
    window
      .threekitPlayer({
        // authToken: "f398adfa-8fa2-4a69-90b1-1fecb77c50c5", //For netlify
        authToken: token, // For localhost
        el: document.getElementById("player"),
        assetId: configuratorId, // you can also use { customId: CUSTOM_ID }
        initialConfiguration: {
          Asset: {
            assetId: "6c30154f-3bec-4c6f-8e41-3dabfc6175e5",
            configuration: { key: "Ash Swivel Chair" },
          },
        },

        showConfigurator: false,
        showAR: true,
        classnames: {
          help: "invisible",
          fullscreen: "invisible",
        },
      })
      .then(async function (player) {
        if (!player) {
          console.error("Player initialization failed");
          return;
        }
        window.player = player;
        // console.log(window.player);
        window.configurator = await player.getConfigurator();
        // console.log(window.configurator.getAttributes());
      })
      .catch((error) => {
        console.error("Player initialization error:", error);
      });
  }, [token]);
  return (
    <>
      <MDBContainer fluid>
        <MDBRow className="d-flex align-items-center justify-content-center viewport">
          <MDBCol
            sm="10"
            md="8"
            lg="8"
            xxl="6"
            className="d-flex justify-content-center player-container"
          >
            {/* <button className="configure-button" onClick={() => openNav()}>
            <MDBIcon fas icon="palette" size="sm" /> &nbsp; Configure
          </button> */}
            <div className="vertical-bar" id="verticalBar">
              <div
                className="d-flex flex-column justify-content-center align-items-center side-menu"
                onClick={() => openNav()}
              >
                <div id="customize" className="customize mt-2 cursor">
                  Customize
                </div>
                <img
                  src="/images/pantone.png"
                  alt="color-option"
                  className="menu-icons pantone-new-ash cursor"
                  data-name="image"
                />
                <div className="icon-header">Color</div>
                <img
                  src="/images/ruler.png"
                  alt="dimension-option"
                  className="menu-icons ruler-new-ash cursor"
                  data-name="image"
                />
                <div className="icon-header">Dimensions</div>
              </div>
            </div>
            <div id="mySidenav" className="sidenav">
              <div
                className="d-flex flex-row justify-content-end align-contents-end mt-2 mb-4 mx-3"
                onClick={() => closeNav()}
              >
                <div id="close">Close</div>
              </div>
              <div className="product-container mx-3">
                <div className="product-title">Ash Swivel Chair</div>
                <div className="item-no">Item 1656713</div>

                <div className="product-attribute mt-3">
                  <div className="">Choose Fabric</div>
                  <div className="d-flex flex-row">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div
                        className="ring-swatch active"
                        data-name="swatch"
                        onClick={(event) =>
                          setColor(
                            event,
                            "87f2959c-8700-4294-a31f-5fc37c5d428a"
                          )
                        }
                      >
                        {/* <Tooltip title="Fabric B" arrow> */}
                        <div className="swatch paxley"></div>
                        {/* </Tooltip> */}
                      </div>
                      <div className="mt-2 fabric-heading"> Dark Gray</div>
                    </div>
                    {/* <div className="d-flex flex-column justify-content-center align-items-center">
                    <div
                      className="ring-swatch"
                      data-name="swatch"
                      onClick={(event) =>
                        setColor(event, "59c96889-29ef-4367-8e94-24c159cdf812")
                      }
                    >
                      <div className="swatch ella-a"></div>
                    </div>
                    <div className="mt-2 fabric-heading">Fabric B</div>
                  </div> */}
                  </div>
                </div>
                <div className="product-attribute mt-3">
                  <div className="d-flex justify-content-center">
                    <div>View Dimensions</div>
                  </div>
                  <div>
                    {" "}
                    <Toggle
                      icons={false}
                      defaultChecked={dimensionEnabled}
                      onChange={() => handleDimensionChange()}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div id="player"></div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default Ash;
