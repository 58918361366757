import { Tooltip } from "@mui/material";
import { MDBCol, MDBContainer, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Toggle from "react-toggle";
import { configuratorId } from "./data";

const EllaOverlay = ({ token }) => {
  const [dimensionEnabled, setDimensionEnabled] = useState(false);
  const [openClose, setOpenClose] = useState(false);

  /**
   * Active Status for Swatches
   */
  const setActive = (event) => {
    // find the swatches collection you want to apply this function too
    var tempName = event.currentTarget.getAttribute("data-name"); // Use getAttribute to access data-* attribute
    var elements = document.querySelectorAll(`[data-name="${tempName}"]`);

    // remove all the active status
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.remove("active-black");
    }

    // add the status on the one that is clicked!!
    event.currentTarget.classList.add("active-black");
  };

  const handleDimensionChange = () => {
    setDimensionEnabled((prevState) => !prevState);
    if (window.configurator) {
      if (dimensionEnabled) {
        window.configurator.setConfiguration({ Dimensions: false });
        setDimensionEnabled(false);
      } else {
        window.configurator.setConfiguration({ Dimensions: true });
        setDimensionEnabled(true);
      }
    }
  };
  const handleOpenCloseChange = () => {
    setOpenClose((prevState) => !prevState);
    if (window.configurator) {
      if (openClose) {
        window.configurator.setConfiguration({ "Open/Close": false });
        setOpenClose(false);
      } else {
        window.configurator.setConfiguration({ "Open/Close": true });
        setOpenClose(true);
      }
    }
  };
  const openNav = () => {
    document.getElementById("mySidenav").style.width = "450px";
    document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
  };
  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
    document.body.style.backgroundColor = "white";
  };

  const setColor = (event, colorId) => {
    if (window.configurator) {
      window.configurator.setConfiguration({
        Fabric: { assetId: colorId },
      });

      setActive(event);
    }
  };
  useEffect(() => {
    const playerElement = document.getElementById("player");
    // console.log(playerElement); // Check if this logs the element
    let dimensionChecked = false;
    window
      .threekitPlayer({
        // authToken: "f398adfa-8fa2-4a69-90b1-1fecb77c50c5", //For netlify
        authToken: token, // For localhost
        el: document.getElementById("player"),
        assetId: configuratorId, // you can also use { customId: CUSTOM_ID }
        initialConfiguration: {
          Product: {
            assetId: "e140cbe9-8458-40c3-a8f1-f54f78a39e42",
            configuration: { key: "Lillian August Ella Convertible Sofa" },
          },
          Fabric: {
            assetId: "87f2959c-8700-4294-a31f-5fc37c5d428a",
            configuration: { key: "MarionSofa_Fabric_B" },
          },
        },

        showConfigurator: false,
        showAR: true,
        classnames: {
          help: "invisible",
          fullscreen: "invisible",
        },
      })
      .then(async function (player) {
        if (!player) {
          console.error("Player initialization failed");
          return;
        }
        window.player = player;
        // console.log(window.player);
        window.configurator = await player.getConfigurator();
        // console.log(window.configurator.getAttributes());
      })
      .catch((error) => {
        console.error("Player initialization error:", error);
      });

    // document.getElementById("zoom").onclick = function () {
    //   window.player.camera.zoom(1);
    // };
  }, [token]);
  return (
    <>
      <MDBContainer fluid>
        <MDBRow className="d-flex align-items-center justify-content-center viewport">
          <MDBCol
            sm="10"
            md="8"
            lg="8"
            xxl="6"
            className="d-flex justify-content-center player-container"
          >
            {/* <button className="configure-button" onClick={() => openNav()}>
            <MDBIcon fas icon="palette" size="sm" /> &nbsp; Configure
          </button> */}
            <div className="overlay">
              <div className="d-flex flex-row">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div
                    className="ring-swatch active-black mx-3"
                    data-name="swatch"
                    onClick={(event) =>
                      setColor(event, "87f2959c-8700-4294-a31f-5fc37c5d428a")
                    }
                  >
                    {/* <Tooltip title="Fabric B" arrow> */}
                    <div className="swatch ella-b"></div>
                    {/* </Tooltip> */}
                  </div>
                  <div className="mt-2 fabric-heading">Fabric A</div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div
                    className="ring-swatch"
                    data-name="swatch"
                    onClick={(event) =>
                      setColor(event, "59c96889-29ef-4367-8e94-24c159cdf812")
                    }
                  >
                    {/* <Tooltip title="Fabric A" arrow> */}
                    <div className="swatch ella-a"></div>
                    {/* </Tooltip> */}
                  </div>
                  <div className="mt-2 fabric-heading">Fabric B</div>
                </div>
              </div>
            </div>
            <div id="mySidenav" className="sidenav">
              <div id="close" onClick={() => closeNav()}>
                &times;
              </div>
              <div className="product-container mx-3">
                <div className="product-title">
                  Lillian August Ella Convertible Sofa
                </div>
                <div className="item-no">Item #######</div>

                <div className="product-attribute mt-3">
                  <div className="">Choose Fabric</div>
                  <div className="d-flex flex-row">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div
                        className="ring-swatch active mx-3"
                        data-name="swatch"
                        onClick={(event) =>
                          setColor(
                            event,
                            "87f2959c-8700-4294-a31f-5fc37c5d428a"
                          )
                        }
                      >
                        {/* <Tooltip title="Fabric B" arrow> */}
                        <div className="swatch ella-b"></div>
                        {/* </Tooltip> */}
                      </div>
                      <div className="mt-2 fabric-heading">Fabric A</div>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div
                        className="ring-swatch"
                        data-name="swatch"
                        onClick={(event) =>
                          setColor(
                            event,
                            "59c96889-29ef-4367-8e94-24c159cdf812"
                          )
                        }
                      >
                        {/* <Tooltip title="Fabric A" arrow> */}
                        <div className="swatch ella-a"></div>
                        {/* </Tooltip> */}
                      </div>
                      <div className="mt-2 fabric-heading">Fabric B</div>
                    </div>
                  </div>
                </div>
                <div className="product-attribute mt-3">
                  <div className="d-flex justify-content-center">
                    <div>Open/Close</div>
                  </div>
                  <div>
                    {" "}
                    <Toggle
                      icons={false}
                      defaultChecked={openClose}
                      onChange={() => handleOpenCloseChange()}
                    />
                  </div>
                </div>
                <div className="product-attribute mt-3">
                  <div className="d-flex justify-content-center">
                    <div>View Dimensions</div>
                  </div>
                  <div>
                    {" "}
                    <Toggle
                      icons={false}
                      defaultChecked={dimensionEnabled}
                      onChange={() => handleDimensionChange()}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div id="player"></div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default EllaOverlay;
