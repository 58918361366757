import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Toggle from "react-toggle";
import { configuratorId, configuratorIdNew } from "./data";

const LowellFullScreenEight = ({ token }) => {
  const [dimensionEnabled, setDimensionEnabled] = useState(false);
  const [assemblyEnabled, setAssemblyEnabled] = useState(false);
  const [assembly, setAssembly] = useState(8);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [openClose, setOpenClose] = useState(false);
  const [itemNo, setItemNo] = useState("1656715");
  /**
   * Active Status for Swatches
   */
  const setActive = (event, className) => {
    // find the swatches collection you want to apply this function too
    var tempName = event.currentTarget.getAttribute("data-name"); // Use getAttribute to access data-* attribute
    var elements = document.querySelectorAll(`[data-name="${tempName}"]`);

    // remove all the active status
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.remove(className);
    }

    // add the status on the one that is clicked!!
    event.currentTarget.classList.add(className);
  };

  const handleDimensionChange = () => {
    setDimensionEnabled((prevState) => !prevState);
    if (window.configurator) {
      if (dimensionEnabled) {
        window.configurator.setConfiguration({ Dimensions: false });
        setDimensionEnabled(false);
      } else {
        window.configurator.setConfiguration({ Dimensions: true });
        setDimensionEnabled(true);
      }
    }
  };

  const handleAssemblyChange = () => {
    setAssemblyEnabled((prevState) => !prevState);
    if (window.configurator) {
      if (assemblyEnabled) {
        window.configurator.setConfiguration({
          "Assembly Type (no. of pieces)": 6,
        });
        setAssemblyEnabled(false);
        setAssembly(6);
        document.getElementById("assembly-default").classList.add("active");
      } else {
        window.configurator.setConfiguration({
          "Assembly Type (no. of pieces)": 8,
        });
        setAssemblyEnabled(true);
        setAssembly(8);
      }
    }
  };

  const setColor = (event, colorId, className, itemNumber) => {
    if (window.configurator) {
      window.configurator.setConfiguration({
        Fabric: { assetId: colorId },
      });

      setActive(event, className);
      setItemNo(itemNumber);
    }
  };

  const setPreset = (event, preset, className) => {
    if (window.configurator) {
      window.configurator.setConfiguration({ Presets: preset });
    }

    setActive(event, className);
  };

  const openNav = () => {
    if (window.innerWidth > 600) {
      document.getElementById("mySidenav").style.width = "450px";
      document.getElementById("verticalBar").style.marginRight = "450px";
      document.getElementById("player-fullscreen").style.width =
        "calc(100% - 550px)";
      document.getElementById("player-fullscreen").style.marginRight =
        "calc(450px + 100px)";
    } else {
      document.getElementById("mySidenav").style.width = "225px";
      document.getElementById("verticalBar").style.marginRight = "225px";
    }
    document.getElementById("verticalBar").style.backgroundColor = "#111111c6";
    document.getElementById("verticalBar").style.color = "white";
    document.getElementById("customize").classList.remove("cursor");

    document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
    var elements = document.querySelectorAll(`[data-name="image"]`);

    // remove all the active status
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.add("invert");
      elements[i].classList.remove("cursor");
    }
  };
  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("verticalBar").style.marginRight = "0";
    document.getElementById("player-fullscreen").style.marginRight = "0";
    document.getElementById("player-fullscreen").style.width = "100%";
    document.getElementById("verticalBar").style.backgroundColor = "#1111114e";
    document.getElementById("verticalBar").style.color = "black";
    document.body.style.backgroundColor = "white";
    document.getElementById("customize").classList.add("cursor");
    var elements = document.querySelectorAll(`[data-name="image"]`);

    // remove all the active status
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.remove("invert");
      elements[i].classList.add("cursor");
    }
  };

  window.addEventListener("resize", () => {
    setScreenWidth(window.innerWidth);
  });
  const handleOpenCloseChange = () => {
    setOpenClose((prevState) => !prevState);
    if (window.configurator) {
      if (openClose) {
        window.configurator.setConfiguration({ "Open/Close": false });
        setOpenClose(false);
      } else {
        window.configurator.setConfiguration({ "Open/Close": true });
        setOpenClose(true);
      }
    }
  };

  useEffect(() => {
    const playerElement = document.getElementById("player-fullscreen");
    // console.log(playerElement); // Check if this logs the element
    let dimensionChecked = false;
    window
      .threekitPlayer({
        // authToken: "f398adfa-8fa2-4a69-90b1-1fecb77c50c5", //For netlify
        authToken: token, // For localhost
        el: document.getElementById("player-fullscreen"),
        assetId: configuratorIdNew, // you can also use { customId: CUSTOM_ID }
        initialConfiguration: {
          Asset: {
            assetId: "27f9e908-afbf-4833-a5da-84d1ea6def21",
            configuration: { key: "Thomasville Lowell Modular Sectional" },
          },
          Fabric: {
            assetId: "08bed84f-92f4-4163-ac7d-7d6686e7a979",
            configuration: { key: "Lowell_Light_Gray" },
          },
          "Assembly Type (no. of pieces)": 8,
        },

        showConfigurator: false,
        showAR: true,
        classnames: {
          help: "invisible",
          fullscreen: "invisible",
        },
      })
      .then(async function (player) {
        if (!player) {
          console.error("Player initialization failed");
          return;
        }
        window.player = player;
        // console.log(window.player);
        window.configurator = await player.getConfigurator();
        // console.log(window.configurator.getAttributes());
      })
      .catch((error) => {
        console.error("Player initialization error:", error);
      });
  }, [token]);
  return (
    <>
      <MDBContainer fluid>
        <MDBRow className="d-flex align-items-center justify-content-center viewport">
          <MDBCol
            size="12"
            className="d-flex justify-content-center player-container"
          >
            {/* <button className="configure-button" onClick={() => openNav()}>
            <MDBIcon fas icon="palette" size="sm" /> &nbsp; Configure
          </button> */}
            <div className="vertical-bar-fullscreen" id="verticalBar">
              <div
                className="d-flex flex-column justify-content-center align-items-center side-menu"
                onClick={() => openNav()}
              >
                <div id="customize" className="customize mt-2 cursor">
                  Customize
                </div>
                <img
                  src="/images/pantone.png"
                  alt="color-option"
                  className="menu-icons pantone-tisdale cursor"
                  data-name="image"
                />
                <div className="icon-header">Colors</div>
                {/* <img
                  src="/images/extend.png"
                  alt="open-close"
                  className="menu-icons extend-tisdale cursor"
                  data-name="image"
                />
                <div className="icon-header">Assembly</div> */}
                <img
                  src="/images/ruler.png"
                  alt="dimension-option"
                  className="menu-icons ruler-lowell cursor"
                  data-name="image"
                />
                <div className="icon-header">Dimensions</div>
                <img
                  src="/images/presets.png"
                  alt="dimension-option"
                  className="menu-icons-preset presets cursor"
                  data-name="image"
                />
                <div className="icon-header">Configs</div>
              </div>
            </div>
            <div id="mySidenav" className="sidenav-fullscreen">
              <div
                className="d-flex flex-row justify-content-end align-contents-end mt-2 mb-4 mx-3"
                onClick={() => closeNav()}
              >
                <div id="close">Close</div>
              </div>
              <div className="product-container mx-3">
                <div className="product-title">
                  Thomasville Lowell Modular Sectional - 8 Piece
                </div>
                <div className="item-no">Item {itemNo}</div>

                <div className="product-attribute mt-3">
                  <div className="product-attribute-heading">Choose Fabric</div>
                  <div className="d-flex flex-row">
                    <div
                      className={`d-flex flex-column justify-content-center align-items-center ${
                        assembly === 8
                          ? screenWidth > 600
                            ? "mx-3"
                            : "mx-1"
                          : ""
                      }`}
                    >
                      <div
                        id="assembly-default"
                        className="ring-swatch active"
                        data-name="swatch"
                        onClick={(event) =>
                          setColor(
                            event,
                            "08bed84f-92f4-4163-ac7d-7d6686e7a979",
                            "active",
                            "1656715"
                          )
                        }
                      >
                        <div className="swatch tisdale-light-gray"></div>
                      </div>
                      <div className="mt-2 fabric-heading text-center">
                        Light Gray
                      </div>
                    </div>{" "}
                    {assembly === 8 ? (
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div
                          className="ring-swatch"
                          data-name="swatch"
                          onClick={(event) =>
                            setColor(
                              event,
                              "7a54c2ef-1a16-41a9-96be-a92a2f45e4f6",
                              "active",
                              "1656714"
                            )
                          }
                        >
                          <div className="swatch tisdale-dark-gray"></div>
                        </div>
                        <div className="mt-2 fabric-heading text-center">
                          Dark Gray
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* <div className="product-attribute mt-3">
                  <div className="d-flex justify-content-center">
                    <div className="product-attribute-heading break-word">
                      Assembly Type
                    </div>
                  </div>
                  <div className="fs-4 mx-3">{assembly}</div>
                  <div className="">
                    <Toggle
                      icons={false}
                      defaultChecked={openClose}
                      onChange={() => handleAssemblyChange()}
                    />
                  </div>
                </div> */}
                <div className="product-attribute mt-3">
                  <div className="d-flex justify-content-center">
                    <div className="product-attribute-heading break-word">
                      View Dimensions
                    </div>
                  </div>
                  <div>
                    {" "}
                    <Toggle
                      icons={false}
                      defaultChecked={dimensionEnabled}
                      onChange={() => handleDimensionChange()}
                    />
                  </div>
                </div>
                <div className="product-attribute-preset mt-3">
                  <div className="product-heading product-attribute-heading">
                    Popular Configurations
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <div className="preset-options-fullscreen">
                      <div
                        className="preset-item d-flex justify-content-start align-items-center my-2 preset-active"
                        data-name="preset"
                        onClick={(event) =>
                          setPreset(event, "Preset_1", "preset-active")
                        }
                      >
                        <img
                          src={
                            assembly === 6
                              ? "/images/preset_5_rockford.png"
                              : "/images/preset_1_alt.png"
                          }
                          alt=""
                          className="present-icon"
                        />
                        <div className="mx-5 mt-2">Option 1</div>
                      </div>{" "}
                      <div
                        className="preset-item d-flex justify-content-start align-items-center my-2"
                        data-name="preset"
                        onClick={(event) =>
                          setPreset(event, "Preset_2", "preset-active")
                        }
                      >
                        <img
                          src={
                            assembly === 6
                              ? "/images/preset_2_lowell.png"
                              : "/images/preset_2_alt.png"
                          }
                          alt=""
                          className="present-icon"
                        />
                        <div className="mx-5 mt-3">Option 2</div>
                      </div>{" "}
                      <div
                        className="preset-item d-flex justify-content-start align-items-center my-2"
                        data-name="preset"
                        onClick={(event) =>
                          setPreset(event, "Preset_3", "preset-active")
                        }
                      >
                        <img
                          src={
                            assembly === 6
                              ? "/images/preset_3_lowell.png"
                              : "/images/preset_3_alt.png"
                          }
                          alt=""
                          className="present-icon"
                        />
                        <div className="mx-5 mt-3">Option 3</div>
                      </div>
                      <div
                        className="preset-item d-flex justify-content-start align-items-center my-2"
                        data-name="preset"
                        onClick={(event) =>
                          setPreset(event, "Preset_4", "preset-active")
                        }
                      >
                        <img
                          src={
                            assembly === 6
                              ? "/images/preset_4_lowell.png"
                              : "/images/preset_4_alt.png"
                          }
                          alt=""
                          className="present-icon"
                        />
                        <div className="mx-5 mt-3">Option 4</div>
                      </div>{" "}
                      {assembly === 6 ? (
                        <div
                          className="preset-item d-flex justify-content-start align-items-center my-2"
                          data-name="preset"
                          onClick={(event) =>
                            setPreset(event, "Preset_5", "preset-active")
                          }
                        >
                          <img
                            src="/images/preset_5_lowell.png"
                            alt=""
                            className="present-icon"
                          />
                          <div className="mx-5 mt-3">Option 5</div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="player-fullscreen"></div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default LowellFullScreenEight;
