import { Tooltip } from "@mui/material";
import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Toggle from "react-toggle";

const Player = ({ assetId, token }) => {
  useEffect(() => {
    const playerElement = document.getElementById("all-in-one");
    // console.log(playerElement); // Check if this logs the element

    window
      .threekitPlayer({
        // authToken: "f398adfa-8fa2-4a69-90b1-1fecb77c50c5", //For netlify
        authToken: token, // For localhost
        el: document.getElementById("all-in-one"),
        assetId: assetId, // you can also use { customId: CUSTOM_ID }
        initialConfiguration: {
          Product: {
            assetId: "1e949871-390e-4e8d-83d4-c157fbdd8c01",
            configuration: { key: "Ash Swivel Chair" },
          },
          Fabric: {
            assetId: "7edc6235-351d-492f-abd6-1a81dcab70c8",
            configuration: { key: "AshSwivelChair_Fabric" },
          },
        },

        showConfigurator: false,
        showAR: true,
      })
      .then(async function (player) {
        if (!player) {
          console.error("Player initialization failed");
          return;
        }
        window.player = player;
        // console.log(window.player);
        window.configurator = await player.getConfigurator();
        // console.log(window.configurator.getAttributes());
      })
      .catch((error) => {
        console.error("Player initialization error:", error);
      });
  }, [assetId, token]);
  return (
    <>
      <div id="all-in-one"></div>
    </>
  );
};

export default Player;
