import React, { useEffect } from "react";

import { configuratorId } from "./data";

const All = ({ token }) => {
  useEffect(() => {
    window
      .threekitPlayer({
        // authToken: "f398adfa-8fa2-4a69-90b1-1fecb77c50c5", //For netlify
        authToken: token, // For localhost
        el: document.getElementById("all-in-one"),
        assetId: configuratorId, // you can also use { customId: CUSTOM_ID }
        initialConfiguration: {},

        publishStage: "published",
        showConfigurator: true,
        showAR: true,
      })
      .then(async function (player) {
        if (!player) {
          console.error("Player initialization failed");
          return;
        }
        window.player = player;
        // console.log(window.player);
        window.configurator = await player.getConfigurator();
        // console.log(window.configurator.getAttributes());
      })
      .catch((error) => {
        console.error("Player initialization error:", error);
      });
  }, [token]);
  return (
    <>
      <div id="all-in-one"></div>
    </>
  );
};

export default All;
