import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/Ash";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Ella from "./Components/Ella";
import All from "./Components/All";
import Player from "./Components/Player";
import Ash from "./Components/Ash";
import EllaOverlay from "./Components/EllaOverlay";
import Paxley from "./Components/Paxley";
import Tisdale from "./Components/Tisdale";
import TisdaleFullScreen from "./Components/TisdaleFullscreen";
import Miles from "./Components/Miles";
import EllaHorizontal from "./Components/EllaHorizontal";
import AshFullScreen from "./Components/AshFullscreen";
import EllaFullScreen from "./Components/EllaFullscreen";
import MilesFullScreen from "./Components/MilesFullscreen";
import PaxleyFullscreen from "./Components/PaxleyFullscreen";
import TisdaleFullscreenDarkGray from "./Components/TisdaleFullscreenDarkGray";
import TisdaleFullscreenBoucle from "./Components/TisdaleFullscreenBoucle";
import RockFordFullscreen from "./Components/RockFordFullscreen";
import MilesRecliningFullScreen from "./Components/MilesRecliningFullscreen";
import LangdonFullScreen from "./Components/LangdonFullscreen";
import LowellFullScreen from "./Components/LowellFullscreen";
import LowellFullScreenEight from "./Components/LowellFullscreenEight";

const token = "51caa26e-e52e-4568-bdaf-b29de005732a"; // For https://true-innovations.3kit.com/
// const token = "77b3d87f-efa9-418c-a49f-6f0e096634ea"; // For localhost //preview
// const token = "8476f9ec-5157-41e1-9a17-27b1f08b27e6"; // For localhost //admin-fts
// const token = "db16cf9c-db53-4e5a-8836-712466ffaf52"; // For Netlify Testing
// const token = "1b8b7382-cf9e-4e73-85d7-b04eb0caa74f"; // For localhost //admin-fts //mobile 192.168.10.164/ella
// const token = "26954f5c-1d68-4218-9d66-325239b1c779"; // For trueinnovations.paracosma.com
// const token = "b771e0ac-ddbc-4ef9-9fd9-64651f439cfc"; // For trueinnovations.paracosma.com //admin-fts
// const token = "f398adfa-8fa2-4a69-90b1-1fecb77c50c5"; // For Netlify
// const token = "97880db1-d9a9-4efb-81f7-7456062a2945"; // For Netlify //admin-fts

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <BrowserRouter>
      <Routes>
        <Route path="/ash" element={<Ash token={token} />}></Route>
        <Route
          path="/ash_fullscreen"
          element={<AshFullScreen token={token} />}
        ></Route>
        <Route path="/miles" element={<Miles token={token} />}></Route>
        <Route
          path="/miles_fullscreen"
          element={<MilesFullScreen token={token} />}
        ></Route>
        <Route
          path="/langdon_fullscreen"
          element={<LangdonFullScreen token={token} />}
        ></Route>
        <Route
          path="/miles_reclining_fullscreen"
          element={<MilesRecliningFullScreen token={token} />}
        ></Route>
        <Route
          path="/paxley_fullscreen"
          element={<PaxleyFullscreen token={token} />}
        ></Route>
        <Route path="/paxley" element={<Paxley token={token} />}></Route>
        <Route path="/tisdale" element={<Tisdale token={token} />}></Route>
        <Route
          path="/tisdale_fullscreen"
          element={<TisdaleFullScreen token={token} />}
        ></Route>
        <Route
          path="/rockford_fullscreen"
          element={<RockFordFullscreen token={token} />}
        ></Route>{" "}
        <Route
          path="/lowell_fullscreen"
          element={<LowellFullScreen token={token} />}
        ></Route>{" "}
        <Route
          path="/lowell_fullscreen_8"
          element={<LowellFullScreenEight token={token} />}
        ></Route>
        <Route
          path="/tisdale_fullscreen_darkgray"
          element={<TisdaleFullscreenDarkGray token={token} />}
        ></Route>{" "}
        <Route
          path="/tisdale_fullscreen_boucle"
          element={<TisdaleFullscreenBoucle token={token} />}
        ></Route>
        <Route path="/ella" element={<Ella token={token} />}></Route>
        <Route
          path="/ella_fullscreen"
          element={<EllaFullScreen token={token} />}
        ></Route>
        <Route path="/" element={<All token={token} />}></Route>
      </Routes>
    </BrowserRouter>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
