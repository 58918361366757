import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Toggle from "react-toggle";
import { configuratorId } from "./data";

const MilesFullScreen = ({ token }) => {
  const [dimensionEnabled, setDimensionEnabled] = useState(false);
  const [openClose, setOpenClose] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [itemNo, setItemNo] = useState("1653362");
  /**
   * Active Status for Swatches
   */
  const setActive = (event, className) => {
    // find the swatches collection you want to apply this function too
    var tempName = event.currentTarget.getAttribute("data-name"); // Use getAttribute to access data-* attribute
    var elements = document.querySelectorAll(`[data-name="${tempName}"]`);

    // remove all the active status
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.remove(className);
    }

    // add the status on the one that is clicked!!
    event.currentTarget.classList.add(className);
  };

  const handleDimensionChange = () => {
    setDimensionEnabled((prevState) => !prevState);
    if (window.configurator) {
      if (dimensionEnabled) {
        window.configurator.setConfiguration({
          Dimensions: false,
          Asset: {
            assetId: "9d896fcf-723e-4ba2-82b3-bfd653fc2825",
            configuration: { key: "Thomasville Miles Sectional" },
          },
        });
        setDimensionEnabled(false);
      } else {
        window.configurator.setConfiguration({
          Dimensions: true,
          Asset: {
            assetId: "9d896fcf-723e-4ba2-82b3-bfd653fc2825",
            configuration: { key: "Thomasville Miles Sectional" },
          },
        });
        setDimensionEnabled(true);
      }
    }
  };

  const setColor = (event, colorId, className, itemNumber) => {
    if (window.configurator) {
      window.configurator.setConfiguration({
        Fabric: { assetId: colorId },
        Asset: {
          assetId: "9d896fcf-723e-4ba2-82b3-bfd653fc2825",
          configuration: { key: "Thomasville Miles Sectional" },
        },
      });

      setActive(event, className);
      setItemNo(itemNumber);
    }
  };

  const setPreset = (event, preset, className) => {
    if (window.configurator) {
      window.configurator.setConfiguration({ Presets: preset });
    }

    setActive(event, className);
  };
  const handleOpenCloseChange = () => {
    setOpenClose((prevState) => !prevState);
    if (window.configurator) {
      if (openClose) {
        window.configurator.setConfiguration({
          "Open/Close": false,
          Asset: {
            assetId: "9d896fcf-723e-4ba2-82b3-bfd653fc2825",
            configuration: { key: "Thomasville Miles Sectional" },
          },
        });
        setOpenClose(false);
      } else {
        window.configurator.setConfiguration({
          "Open/Close": true,
          Asset: {
            assetId: "9d896fcf-723e-4ba2-82b3-bfd653fc2825",
            configuration: { key: "Thomasville Miles Sectional" },
          },
        });
        setOpenClose(true);
      }
    }
  };
  const openNav = () => {
    if (window.innerWidth > 600) {
      document.getElementById("mySidenav").style.width = "450px";
      document.getElementById("verticalBar").style.marginRight = "450px";
      document.getElementById("player-fullscreen").style.width =
        "calc(100% - 550px)";
      document.getElementById("player-fullscreen").style.marginRight =
        "calc(450px + 100px)";
    } else {
      document.getElementById("mySidenav").style.width = "225px";
      document.getElementById("verticalBar").style.marginRight = "225px";
    }
    document.getElementById("verticalBar").style.backgroundColor = "#111111c6";
    document.getElementById("verticalBar").style.color = "white";
    document.getElementById("customize").classList.remove("cursor");

    document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
    var elements = document.querySelectorAll(`[data-name="image"]`);

    // remove all the active status
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.add("invert");
      elements[i].classList.remove("cursor");
    }
  };
  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("verticalBar").style.marginRight = "0";
    document.getElementById("player-fullscreen").style.marginRight = "0";
    document.getElementById("player-fullscreen").style.width = "100%";
    document.getElementById("verticalBar").style.backgroundColor = "#1111114e";
    document.getElementById("verticalBar").style.color = "black";
    document.body.style.backgroundColor = "white";
    document.getElementById("customize").classList.add("cursor");
    var elements = document.querySelectorAll(`[data-name="image"]`);

    // remove all the active status
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.remove("invert");
      elements[i].classList.add("cursor");
    }
  };

  window.addEventListener("resize", () => {
    setScreenWidth(window.innerWidth);
  });

  useEffect(() => {
    const playerElement = document.getElementById("player-fullscreen");
    // console.log(playerElement); // Check if this logs the element
    let dimensionChecked = false;
    window
      .threekitPlayer({
        // authToken: "f398adfa-8fa2-4a69-90b1-1fecb77c50c5", //For netlify
        authToken: token, // For localhost
        el: document.getElementById("player-fullscreen"),
        assetId: configuratorId, // you can also use { customId: CUSTOM_ID }
        initialConfiguration: {
          Asset: {
            assetId: "9d896fcf-723e-4ba2-82b3-bfd653fc2825",
            configuration: { key: "Thomasville Miles Sectional" },
          },

          Fabric: {
            assetId: "019f7113-8eed-42f4-ae57-35a46f30bb94",
          },
          Camera: "Sofa Camera",
        },

        publishStage: "published",
        showConfigurator: false,
        showAR: true,
        classnames: {
          help: "invisible",
          fullscreen: "invisible",
        },
      })
      .then(async function (player) {
        if (!player) {
          console.error("Player initialization failed");
          return;
        }
        window.player = player;
        // console.log(window.player);
        window.configurator = await player.getConfigurator();
        // console.log(window.configurator.getAttributes());
      })
      .catch((error) => {
        console.error("Player initialization error:", error);
      });
  }, [token]);
  return (
    <>
      <MDBContainer fluid>
        <MDBRow className="d-flex align-items-center justify-content-center viewport">
          <MDBCol
            size="12"
            className="d-flex justify-content-center player-container"
          >
            {/* <button className="configure-button" onClick={() => openNav()}>
            <MDBIcon fas icon="palette" size="sm" /> &nbsp; Configure
          </button> */}
            <div className="vertical-bar-fullscreen" id="verticalBar">
              <div
                className="d-flex flex-column justify-content-center align-items-center side-menu"
                onClick={() => openNav()}
              >
                <div id="customize" className="customize mt-2 cursor">
                  Customize
                </div>
                <img
                  src="/images/pantone.png"
                  alt="color-option"
                  className="menu-icons pantone cursor"
                  data-name="image"
                />
                <div className="icon-header">Colors</div>
                <img
                  src="/images/extend.png"
                  alt="open-close"
                  className="menu-icons extend-ella cursor"
                  data-name="image"
                />
                <div className="icon-header">Open</div>
                <img
                  src="/images/ruler.png"
                  alt="dimension-option"
                  className="menu-icons ruler-ella cursor"
                  data-name="image"
                />
                <div className="icon-header">Dimensions</div>
              </div>
            </div>
            <div id="mySidenav" className="sidenav-fullscreen">
              <div
                className="d-flex flex-row justify-content-end align-contents-end mt-2 mb-4 mx-3"
                onClick={() => closeNav()}
              >
                <div id="close">Close</div>
              </div>
              <div className="product-container mx-3">
                <div className="product-title">Thomasville Miles Sectional</div>

                <div className="item-no">Item {itemNo}</div>

                <div className="product-attribute mt-3">
                  <div className="product-attribute-heading">Choose Fabric</div>
                  <div className="d-flex flex-row">
                    <div
                      className={`d-flex flex-column justify-content-center align-items-center ${
                        screenWidth > 600 ? "mx-3" : "mx-1"
                      }`}
                    >
                      <div
                        className="ring-swatch"
                        data-name="swatch"
                        onClick={(event) =>
                          setColor(
                            event,
                            "f7c6c306-f56a-4dd9-adbe-2a6fdebfeb5e",
                            "active",
                            "1695500"
                          )
                        }
                      >
                        {/* <Tooltip title="Fabric B" arrow> */}
                        <div className="swatch miles-b"></div>
                        {/* </Tooltip> */}
                      </div>
                      <div className="mt-2 fabric-heading text-center">
                        Blue
                      </div>
                    </div>
                    <div
                      className={`d-flex flex-column justify-content-center align-items-center`}
                    >
                      <div
                        className="ring-swatch active"
                        data-name="swatch"
                        onClick={(event) =>
                          setColor(
                            event,
                            "019f7113-8eed-42f4-ae57-35a46f30bb94",
                            "active",
                            "1653362"
                          )
                        }
                      >
                        <div className="swatch miles-g"></div>
                      </div>
                      <div className="mt-2 fabric-heading text-center">
                        Gray
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <div className="product-attribute mt-3">
                  <div className="d-flex justify-content-center">
                    <div className="product-attribute-heading">Open/Close</div>
                  </div>
                  <div>
                    {" "}
                    <Toggle
                      icons={false}
                      defaultChecked={openClose}
                      onChange={() => handleOpenCloseChange()}
                    />
                  </div>
                </div>
                <div className="product-attribute mt-3">
                  <div className="d-flex justify-content-center">
                    <div className="product-attribute-heading">
                      View Dimensions
                    </div>
                  </div>
                  <div>
                    {" "}
                    <Toggle
                      icons={false}
                      defaultChecked={dimensionEnabled}
                      onChange={() => handleDimensionChange()}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div id="player-fullscreen"></div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default MilesFullScreen;
